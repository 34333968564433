import React from 'react';
import classNames from 'classnames';
import { ReactComponent as LogoWhite } from 'assets/logo/logo-white.svg';
import { ReactComponent as LogoBlue } from 'assets/logo/logo-blue.svg';

export enum LogoColor {
  White = 'white',
  Blue = 'blue',
}

interface LogoProps extends React.ComponentProps<'svg'> {
  size?: 'md' | 'lg';
  color?: LogoColor;
}

export default function Logo(props: LogoProps) {
  const { size, className, color = 'white', ...rest } = props;
  let width = 0;
  const cc = classNames(className, 'text-primary-600');

  switch (size) {
    case 'md':
      width = 128;
      break;
    case 'lg':
      width = 196;
      break;
    default:
      width = 96;
  }

  if (color === LogoColor.Blue) {
    return <LogoBlue width={width} className={cc} {...rest} />;
  }

  return <LogoWhite width={width} className={cc} {...rest} />;
}
