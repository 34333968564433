import React, { createContext, useContext, useReducer } from 'react';
import { Simulation } from 'types/Simulation';

type Action =
  | { type: 'ADD_SIMULATION'; payload: LocalSimulation }
  | { type: 'REMOVE_SIMULATION'; payload: string }
  | { type: 'REMOVE_ALL_SIMULATIONS' };

export type LocalSimulation = Simulation & {
  id: string;
  documentsJustificatifsOperation?: string[];
  visiteTechnique?: boolean;
};

const simulationsReducer = (
  state: LocalSimulation[],
  action: Action,
): LocalSimulation[] => {
  switch (action.type) {
    case 'ADD_SIMULATION':
      return [...state, action.payload];
    case 'REMOVE_SIMULATION':
      return state.filter((simulation) => simulation.id !== action.payload);
    case 'REMOVE_ALL_SIMULATIONS':
      return [];
    default:
      return state;
  }
};

interface SimulationsContextProps {
  state: LocalSimulation[];
  dispatch: React.Dispatch<Action>;
  addSimulation: (
    simulation: Simulation,
    documentsJustificatifsOperation?: string[],
    visiteTechnique?: boolean,
  ) => void;
  removeSimulation: (simulationId: string) => void;
  removeAllSimulations: (e?: React.MouseEvent) => void;
}

export const SimulationsContext = createContext<
  SimulationsContextProps | undefined
>(undefined);

export const SimulationsProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(simulationsReducer, []);

  const removeAllSimulations = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    dispatch({ type: 'REMOVE_ALL_SIMULATIONS' });
  };

  const removeSimulation = (simulationId: string) => {
    dispatch({ type: 'REMOVE_SIMULATION', payload: simulationId });
  };

  const addSimulation = (
    simulation: LocalSimulation,
    documentsJustificatifsOperation?: string[],
    visiteTechnique?: boolean,
  ) => {
    dispatch({
      type: 'ADD_SIMULATION',
      payload: {
        ...simulation,
        documentsJustificatifsOperation,
        visiteTechnique,
      },
    });
  };

  return (
    <SimulationsContext.Provider
      value={{
        state,
        dispatch,
        addSimulation,
        removeSimulation,
        removeAllSimulations,
      }}
    >
      {children}
    </SimulationsContext.Provider>
  );
};

/**
 * Liste les simulations effectuées pendant la session de l'utilisateur
 * @returns Le contexte des simulations
 */
export const useCurrentSimulations = () => {
  return useContext(SimulationsContext)!;
};
