import 'index.css';
import 'overrides.scss';
// import * as Sentry from '@sentry/react';

// const sentryEnabled = !process.env.REACT_APP_DISABLE_SENTRY;

// Sentry.init({
//   dsn: sentryEnabled ? "https://2a302a893dafa9a93aca21e0f5474b7f@o4508086168125440.ingest.de.sentry.io/4508399184445520" : undefined,
//   enabled: sentryEnabled,
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: [
//     /^https:\/\/dev\.plateforme-logement\.hellio\.dev/,
//     /^https:\/\/staging\.plateforme-logement\.hellio\.dev/,
//     /^https:\/\/plateforme-logement\.hellio\.com/,
//   ],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Router } from 'react-router';
import App from 'App';
import { createBrowserHistory } from 'history';
import { init as initializeUserpilot } from 'tools/Userpilot';

// Initialize Userpilot
initializeUserpilot();

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
const history = createBrowserHistory();

root.render(
  <Router history={history}>
    <App />
  </Router>,
);
