import { User } from 'types/User';
import { Userpilot } from 'userpilot';
import { getEnv } from 'utils/env';

declare global {
  interface Window {
    isUserpilotInitialized: boolean;
  }
}

export function init() {
  const env = getEnv();
  window.isUserpilotInitialized = false;

  switch (env) {
    case 'prod':
      setup(process.env.REACT_APP_USERPILOT_TOKEN_PROD);
      break;
    case 'staging':
      setup(process.env.REACT_APP_USERPILOT_TOKEN_STAGING);
      break;
    case 'dev':
      setup(process.env.REACT_APP_USERPILOT_TOKEN_STAGING);
      break;
    default:
      setup(process.env.REACT_APP_USERPILOT_TOKEN_LOCAL);
  }
}

function setup(token: string | undefined) {
  if (token) {
    window.isUserpilotInitialized = true;
    Userpilot.initialize(token);
  }
}

export function identify(user: User) {
  if (window.isUserpilotInitialized) {
    Userpilot.identify(user.id.toString(), {
      name: `${user.firstname} ${user.lastname}`,
      email: user.email,
      created_at: user.createdAt,
    });
  }
}

export function reload() {
  if (window.isUserpilotInitialized) {
    Userpilot.reload();
  }
}
