import React from 'react';
import { Grid, Col, Row, theme, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Logo from 'components/Logo/Logo';
const { useBreakpoint } = Grid;

const AuthLayout = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const { lg } = useBreakpoint();
  return (
    <Row
      align="stretch"
      wrap
      style={{
        minHeight: '100vh',
        backgroundColor: token.colorBgLayout,
      }}
    >
      <Col
        xs={0}
        sm={0}
        md={0}
        lg={8}
        style={{
          padding: '40px',
          backgroundColor: token.colorPrimary,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            boxSizing: 'border-box',
          }}
        >
          <Link to="/" style={{ height: 90 }}>
            <Logo />
          </Link>
          <Typography.Title
            style={{
              color: token.colorPrimaryText,
              whiteSpace: 'pre-line',
            }}
            level={1}
          >
            {t('auth.hero')}
          </Typography.Title>
          <div />
        </div>
      </Col>
      <Col flex={1}>
        {!lg && (
          <div className="w-full p-4 bg-bleu-hellio">
            <Link to="/" style={{ height: 90 }}>
              <Logo />
            </Link>
            <Typography.Title
              style={{
                color: token.colorPrimaryText,
              }}
              className="!text-lg"
              level={1}
            >
              {t('auth.hero')}
            </Typography.Title>
          </div>
        )}
        {children}
      </Col>
    </Row>
  );
};

export default React.memo(AuthLayout);
