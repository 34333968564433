let prefixer: string | undefined;

const hostname: string = window.location.hostname;
const port: string = window.location.port;

switch (hostname) {
  case process.env.REACT_APP_STAGING_HOST:
    prefixer = process.env.REACT_APP_STAGING_API_URL;
    break;
  case process.env.REACT_APP_DEV_HOST:
    prefixer = process.env.REACT_APP_DEV_API_URL;
    break;
  case process.env.REACT_APP_PROD_HOST:
    prefixer = process.env.REACT_APP_PROD_API_URL;
    break;
  default:
    if (port === '3000') {
      prefixer = process.env.REACT_APP_LOCAL_API_URL;
    } else {
      prefixer = process.env.REACT_APP_DEV_API_URL;
    }
}

const authUrl = `${prefixer}/auth/`;
const userUrl = `${prefixer}/user/`;
const userMeUrl = `${userUrl}me/`;
const calculatriceCEEUrl = `${prefixer}/calculatriceCEE/`;
const soumissionDossierUrl = `${prefixer}/soumissionDossier/`;
const categoriesRevenusUrl = `${prefixer}/categoriesRevenus/`;
const calculatriceMPRUrl = `${prefixer}/calculatriceMPR/`;

export const AuthUrls: Record<string, string> = {
  PASSWORD_LOGIN: `${authUrl}login`,
  RESET_PASSWORD_INIT: `${authUrl}forgot-password`,
  RESET_PASSWORD: `${authUrl}reset-password`,
  CREATE_PASSWORD: `${authUrl}create-password`,
  REFRESH_TOKEN: `${authUrl}refresh-token`,
  IMPERSONATE: `${authUrl}impersonate`,
  CREATE_PASSWORD_TOKEN_STATUS: `${prefixer}/auth/activation-token/status`,
};

export const UserUrls: Record<string, string> = {
  GET_CONNECTED_INFO: `${userMeUrl}`,
  GET_ACCOUNT_INFO: `${userUrl}infos`,
  GET_ACCOUNT_SF_OWNER_INFO: `${userMeUrl}sf-owner`,
  GET_ACCOUNT_SF_NOTIFICATIONS: `${userMeUrl}sf-notifications`,
  RESEND_FIRST_EMAIL: `${prefixer}/user/resend-first-email`,
};

export const PickListUrls = {
  GET: (objectType: string, fieldName: string) =>
    `${prefixer}/${objectType}/picklist/${fieldName}`,
};

export const DossiersUrls = {
  ADD: `${prefixer}/dossiers`,
  EXPORT: `${prefixer}/dossiers/export`,
  LIST: `${prefixer}/dossiers`,
  GET_REFERENTS_COMMERCIAUX: `${prefixer}/dossiers/referents-commerciaux`,
  GET: (dossierId: string, operationId: string) =>
    `${prefixer}/dossiers/${dossierId}/${operationId}`,
  PUT: (dossierId: string) => `${prefixer}/dossiers/${dossierId}`,
  OPERATIONS: (dossierId: string, offset: number = 0, limit: number = 10) =>
    `${prefixer}/dossiers/${dossierId}/operations?offset=${offset}&limit=${limit}`,
  OPERATION: (dossierId: string, operationId: string) =>
    `${prefixer}/dossiers/${dossierId}/operations/${operationId}`,
  OPERATION_ATTACHMENTS: (dossierId: string, operationId: string) =>
    `${prefixer}/dossiers/${dossierId}/operations/${operationId}/attachments`,
  LITIGE_ATTACHMENTS: (dossierId: string, litigeId: string) =>
    `${prefixer}/dossiers/${dossierId}/litiges/${litigeId}/attachments`,
};

export const AttachmentsUrls = {
  DOWNLOAD_ATTACHMENT: (token: string) =>
    `${prefixer}/attachments/download/${token}`,
  GET_DOWNLOAD: (attachmentId: string) =>
    `${prefixer}/attachments/get-download/${attachmentId}`,
  DELETE: (attachmentId: string) => `${prefixer}/attachments/${attachmentId}`,
};

export const CalculatriceCEEUrls: Record<string, string> = {
  LISTE_FOST: `${calculatriceCEEUrl}listeFost`,
  CRITERES_ELIGIBILITE: `${calculatriceCEEUrl}criteresEligibilite`,
  CHAMPS_REQUIS: `${calculatriceCEEUrl}champsRequis`,
  VOLUME_ET_PRIME_CEE: `${calculatriceCEEUrl}volumeEtPrimeCEE`,
  SIMULATIONS_UPDATE: `${calculatriceCEEUrl}simulations/update`,
};

export const SoumissionDossierUrls: Record<string, string> = {
  ADRESSES: `${soumissionDossierUrl}adresses`,
  BENEFICIAIRES: `${soumissionDossierUrl}beneficiaires`,
  SIRETS: `${soumissionDossierUrl}sirets`,
  INTERLOCUTEURS: `${soumissionDossierUrl}interlocuteurs`,
  POST: `${soumissionDossierUrl}insert`,
};

export const ExternalUrls = {
  GET_NEWS_RSS: 'https://www.hellio.com/actualites/rss.xml',
};

export const LOCATION_URL = {
  SEARCH: (searchValue: string) =>
    `https://api-adresse.data.gouv.fr/search/?q=${searchValue}&postcode=${searchValue}&type=municipality`,
};

export const CategoriesRevenus: Record<string, string> = {
  PALIERS_MPR: `${categoriesRevenusUrl}paliersMPR`,
};

export const CalculatriceMPRUrls: Record<string, string> = {
  CHAMPS_REQUIS: `${calculatriceMPRUrl}champsRequis`,
  PRIME: `${calculatriceMPRUrl}prime`,
};

export const SimulationsUrls = {
  SAVE: `${prefixer}/simulations`,
  LIST: `${prefixer}/simulations`,
  EXPORT: `${prefixer}/simulations/export`,
  VIEW(id: string) {
    return `${prefixer}/simulations/${id}`;
  },
};
