import { Attachment } from 'types/Attachment';

export enum OperationStatus {
  Eligible = 'Éligible',
  AConfirmer = 'Éligibilité à confirmer',
  Recommandations = 'Recommandations',
  Annule = 'Annulé',
  DossierEnCoursDeValidation = 'Dossier en cours de validation',
  Incomplet = 'Incomplet',
  DossierNonConforme = 'Dossier non conforme',
  RemisAuControle = 'Remis au Contrôle',
  PreValide = 'Pré validé',
  BonPourPrime = 'Bon pour Prime',
  NonConforme = 'Non conforme (Qualité)',
  ActionEnCours = 'Action en cours',
  EnAttenteDeBureauDeControle = 'En attente de bureau de contrôle',
  ChantierNonConforme = 'Chantier non conforme',
  Litige = 'Litige',
  Refuse = 'Refusé',
  Retire = 'Retiré du dépôt',
  Abandon = 'Abandon',
  DossierRecu = 'Dossier reçu',
  ACorriger = 'A corriger',
}

export const OperationStatusKeys = Object.fromEntries(
  Object.entries(OperationStatus).map(([key, value]) => [value, key]),
);

export interface OperationHistoryItem {
  date: string;
  value: OperationStatus;
}

export interface Operation {
  id: string;
  libelle?: string;
  fost?: string;
  montantPrime?: number;
  statut?: OperationStatus;
  criteresEligibilite?: string;
  mentionsFacture?: string;
}
export interface OperationDetails extends Operation {
  history: OperationHistoryItem[];
  attachments: Attachment[];
}

export interface Operations {
  records: Operation[];
  totalSize: number;
}
