import { FormInstance, UploadFile } from 'antd';
import { DossierDocumentsFormType } from 'components/DossierDocumentsForm';
import Colors from 'const/colors';
import { DossierMPRStatus, DossierStatus, ModifyDossier } from 'types/Dossier';
import { OperationStatus } from 'types/Operation';

const orange = Colors.GrandPublic;
const red = Colors.GestionnaireHabitat;
const green = Colors.Agriculture;
const gris = Colors.Industriels;

export const getDossierStatusColor = (
  status: DossierStatus | DossierMPRStatus | OperationStatus,
): string => {
  // Référence couleurs: https://www.figma.com/design/F2meC6lua7aaJkBZbouD0w/Plateforme-Partenaire-Logement?node-id=968-96&m=dev
  switch (status) {
    case DossierStatus.EtudeEnCours:
    case DossierStatus.InformationsRequises:
    case DossierStatus.OffreEmise:
    case DossierStatus.DemandeInformations:
    case DossierStatus.ChantierEnCours:
    case DossierStatus.DocumentsEnAttente:
    case DossierStatus.EnAttenteDeControle:
    case DossierStatus.EnCoursDeValidation:
      return orange;

    case DossierStatus.ChantierNonConforme:
    case DossierStatus.Incomplet:
      return red;

    case DossierStatus.PrimeProPayee:
    case DossierStatus.PrimeBeneficiairePayee:
    case DossierStatus.PrimeGlobalePayee:
    case DossierStatus.Valide:
      return green;

    case DossierStatus.Refuse:
    case DossierStatus.Abandon:
    default:
      return gris;
  }
};

export const getDossierMprStatusColor = (
  status: DossierMPRStatus,
): string | undefined => {
  // Référence couleurs: https://www.figma.com/design/F2meC6lua7aaJkBZbouD0w/Plateforme-Partenaire-Logement?node-id=968-96&m=dev
  switch (status) {
    case DossierMPRStatus.EnCoursDeTraitement:
      return orange;

    case DossierMPRStatus.Refuse:
    case DossierMPRStatus.DossierNonConforme:
    case DossierMPRStatus.DemandeDeProrogationEnCours:
      return red;

    case DossierMPRStatus.Finalise:
    case DossierMPRStatus.PrimeAgreee:
    case DossierMPRStatus.DemandeDeSoldeDeposee:
    case DossierMPRStatus.PaiementANAHRecu:
    case DossierMPRStatus.PrimeMPRPayee:
      return green;

    case DossierMPRStatus.DossierRefuse:
    case DossierMPRStatus.PrimeArriveeAForclusion:
    case DossierMPRStatus.Abandon:
      return gris;

    default:
      return;
  }
};

export function appendDossierFormDataDocuments(
  files: UploadFile[],
  formData: FormData,
  prefix: string[],
) {
  files
    .filter(({ url }: UploadFile) => !url) // Ignorer les fichiers déjà uploadés
    .forEach((file: UploadFile) => {
      // Comme il n'est pas possible de donner des noms de champs dynamiques pour les fichiers,
      // on concatène le type et le sous-type de fichier au nom de fichier en utilisant des ***
      // (qui sont des caractères inutilisables en temps normal dans un nom de fichier)
      const newName = [...prefix, file.name].join(' *** ');
      // Créée un clone du fichier avec le nouveau nom
      const newFile = new File([file as unknown as File], newName, {
        type: file.type,
      });
      // Ajoute le fichier au données du formulaire
      formData.append('documents', newFile);
    });
}

export function getModifyDossierFormData(
  form: FormInstance<DossierDocumentsFormType>,
): FormData {
  const formData = new FormData();

  const data: ModifyDossier = {};
  const addData = <K extends keyof ModifyDossier>(
    fieldName: K,
    value: ModifyDossier[K],
  ) => {
    if (value !== null && value !== undefined) {
      data[fieldName] = value;
    }
  };
  const addDateData = (fieldName: keyof ModifyDossier) =>
    addData(fieldName, form.getFieldValue(fieldName)?.format('YYYY-MM-DD'));
  const addArrayData = (fieldName: keyof ModifyDossier) =>
    addData(fieldName, form.getFieldValue(fieldName) || []);

  const appendFiles = (
    fieldName: keyof DossierDocumentsFormType,
    category?: string,
  ) => {
    const prefix = category !== undefined ? [fieldName, category] : [fieldName];
    const files =
      form.getFieldValue(
        category !== undefined ? ['documentsOperation', category] : fieldName,
      ) || [];

    appendDossierFormDataDocuments(files, formData, prefix);
  };

  appendFiles('documentsDebutTravaux');
  addDateData('dateVisiteTechnique');
  addDateData('dateEditionDevis');
  if (form.getFieldValue('devisSigne')) {
    addDateData('dateSignatureDevis');
  } else {
    addData('dateSignatureDevis', '');
  }

  appendFiles('documentsRAI');
  addDateData('dateRAI');

  appendFiles('documentsFinTravaux');
  addDateData('dateEmissionFacture');

  appendFiles('documentsAH');

  appendFiles('documentsTechnique');

  const documentsOperation = form.getFieldValue('documentsOperation') || [];
  Object.entries(documentsOperation).forEach(([category]) =>
    appendFiles('documentsOperation', category),
  );

  appendFiles('documentsAutre');

  // Fichiers à supprimer
  addArrayData('deletedDocumentUIDs');

  // Données du dossier au format JSON
  formData.append('data', JSON.stringify(data));

  return formData;
}
