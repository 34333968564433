import Colors from 'const/colors';
import { OperationStatus } from 'types/Operation';

const orange = Colors.GrandPublic;
const red = Colors.GestionnaireHabitat;
const green = Colors.Agriculture;
const gray = Colors.Industriels;
const black = Colors.Black;

export const getDossierOperationStatusColor = (
  status: OperationStatus,
): string => {
  switch (status) {
    case OperationStatus.Litige:
    case OperationStatus.DossierNonConforme:
    case OperationStatus.Incomplet:
    case OperationStatus.ChantierNonConforme:
      return red;
    case OperationStatus.Abandon:
    case OperationStatus.Refuse:
      return gray;
    case OperationStatus.BonPourPrime:
      return green;
    case OperationStatus.RemisAuControle:
    case OperationStatus.DossierEnCoursDeValidation:
    case OperationStatus.EnAttenteDeBureauDeControle:
    case OperationStatus.PreValide:
    case OperationStatus.ActionEnCours:
    case OperationStatus.NonConforme:
      return orange;
    default:
      return black;
  }
};
