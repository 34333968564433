import { Operation } from 'types/Operation';
import { Attachment } from 'types/Attachment';
import { Litige } from 'types/Litige';

export interface CreatedBy {
  LastName: string;
  FirstName: string;
  Email: string;
}

export interface File {
  name: string;
  content: string;
}

export interface Lead {
  Company: string;
  Description: string;
  Nom_du_Site_Chantier__c: string;
  Adresse_du_chantier__c: string;
  Code_Postal_du_chantier__c: string;
  Ville_du_chantier__c: string;
  Date_previsionnelle_debut__c: string;
  Date_fin_travaux__c: string;
  Le_logement_a_plus_de_2_ans__c: string;
  Nature_des_travaux__c: string;
  Secteur__c: string;
  Type_d_operation_GC__c: string;
  Surface_tot_ventilee_partie_rehabilitee__c: number;
  Ancienne_energie_de_chauffage_batiment__c: string;
  Nouvelle_energie_de_chauffage_batiment__c: string;
  Surface_chauffee__c: number;
  Surface_chauffee_partie_rehabilitee__c: number;
  Salutation: string;
  Lastname: string;
  Firstname: string;
  Email: string;
  Phone: string;
  Mod_le_d_incitation_financi_re_souhait__c: string;
}

export interface CreateDossierPayload {
  CreatedBy: CreatedBy;
  Lead: Lead;
  Files: File[];
}

export enum DossierType {
  B2B = 'B2B', // BTP
  HCPP = 'HCPP', // Habitat Collectif Personne Physique
  HCPM = 'HCPM', // Habitat Collectif Personne Morale
}

export enum DossierStatus {
  EtudeEnCours = 'Etude en cours',
  OffreEmise = 'Offre émise',
  ChantierEnCours = 'Chantier en cours',
  DocumentsEnAttente = 'Documents en attente',
  EnAttenteDeControle = 'En attente de passage bureau de contrôle',
  Incomplet = 'Dossier incomplet',
  Valide = 'Dossier validé',
  PrimeProPayee = 'Prime Pro payée',
  PrimeBeneficiairePayee = 'Part Bénéficiaire payée',
  PrimeGlobalePayee = 'Prime Globale Payée',
  Refuse = 'Refusé',
  Abandon = 'Abandon',
  DemandeInformations = "Demande d'informations",
  EnCoursDeValidation = 'Dossier en cours de validation',
  InformationsRequises = 'Informations complémentaires requises',
  ChantierNonConforme = 'Chantier non conforme',
}

export enum DossierSortFields {
  id = 'id',
  // beneficiaire = 'beneficiaire',
  // nomSite = 'site_nom',
  adresse = 'site_adresse',
  date = 'dateModification',
  dateFinTravaux = 'chantier_dateFinTravaux',
  reference = 'reference',
  // montantPrimes = 'montant_primes', // Désactivé le temps d'avoir un champ custom comprenant prime CEE + prime MPR
  statut = 'statut',
  referentCommercial = 'referentCommercial',
}

export enum DossierMPRStatus {
  CERFAEnvoye = 'Désignation mandataire',
  EnCoursDeTraitement = 'En cours de traitement',
  Refuse = 'Dossier annulé, prime MPR refusée',
  Finalise = 'Dossier finalisé',
  DossierNonConforme = 'Dossier non conforme',
  DemandeDeProrogationEnCours = 'Demande de prorogation en cours',
  PrimeAgreee = 'Prime agréée',
  DemandeDeSoldeDeposee = 'Demande de solde déposée',
  PaiementANAHRecu = 'Paiement ANAH reçu',
  DossierRefuse = 'Dossier refusé',
  PrimeArriveeAForclusion = 'Prime arrivée à forclusion',
  Abandon = 'Abandon',
  MandatAccepte = 'Mandat accepté',
  DemandeDeSubventionDeposee = 'Demande de subvention déposée',
  PrimeMPRPayee = "Prime MaPrimeRénov' payée",
  ChantierNonConforme = 'Chantier non conforme',
}

export enum DossierMPRStatusKeys {
  CERFAEnvoye = 'CERFA envoyé',
  MandatAccepte = 'Mandat accepté',
  DemandeDeSubventionEffectue = 'Demande de subvention effectuée',
  DossierNonConformeSubvention = 'Dossier non conforme subvention',
  DossierNonConformeSolde = 'Dossier non conforme solde',
  DemandeDeProrogationEnCours = 'Demande de prorogation en cours',
  BonPourTravauxEnvoye = 'Bon pour travaux envoyé',
  DemandeDePaiement = 'Demande de paiement',
  PaiementEffectue = 'Paiement effectué',
  DossierRefuse = 'Dossier refusé',
  ACorriger = 'A corriger',
  PrimeArriveeAForclusion = 'Prime arrivée à forclusion',
  Abandon = 'Abandon',
}

export enum DossierDocumentTypes {
  DebutTravaux = 'DebutTravaux',
  RAI = 'RAI',
  FinTravaux = 'FinTravaux',
  AH = 'AH',
  Technique = 'Technique',
  Operation = 'Operation',
  Autre = 'Autre',
}

export const FilterDossierStatus = [
  DossierStatus.EtudeEnCours,
  DossierStatus.InformationsRequises,
  DossierStatus.OffreEmise,
  DossierStatus.DemandeInformations,
  DossierStatus.ChantierEnCours,
  DossierStatus.DocumentsEnAttente,
  DossierStatus.EnCoursDeValidation,
  DossierStatus.Incomplet,
  DossierStatus.Valide,
  DossierStatus.EnAttenteDeControle,
  DossierStatus.ChantierNonConforme,
  DossierStatus.PrimeProPayee,
  DossierStatus.PrimeBeneficiairePayee,
  DossierStatus.PrimeGlobalePayee,
  DossierStatus.Refuse,
  DossierStatus.Abandon,
];

export const DossierStatusKeys = Object.fromEntries(
  Object.entries(DossierStatus).map(([key, value]) => [value, key]),
);

export interface Base64File {
  name: string;
  content: string;
  type?: string;
}

export interface DossierHistoryItem {
  date: string;
  value: DossierStatus;
}

export class Entreprise {
  readonly raisonSociale?: string;
  readonly siret?: string;
  readonly adresse?: string;
  readonly codePostal?: string;
  readonly ville?: string;
  readonly email?: string;
  readonly telephone?: string;
}

export interface Chantier {
  entreprise?: Entreprise;
  natureTravaux?: string;
  dateDebutTravaux?: string;
  dateFinTravaux?: string;
}

export interface Site {
  type?: string;
  nom?: string;
  usage?: string;
  adresse?: string;
  codePostal?: string;
  ville?: string;
}

export interface Batiment {
  ancien?: boolean;
  ancienneEnergieChauffage?: string;
  nouvelleEnergieChauffage?: string;
  surfaceChauffee?: number;
  surfaceChauffeeRehabilitee?: number;
  surfaceVentileeRehabilitee?: number;
}

export interface Personne {
  firstname?: string;
  lastname?: string;
  email?: string;
}

export interface Beneficiaire extends Personne {
  raisonSociale?: string;
  siren?: string;
  adresse?: string;
  codePostal?: string;
  ville?: string;
  civilite?: string;
  telephone?: string;
}

export interface DossierDetails {
  id: string;
  reference: string;
  type: DossierType;
  statut: DossierStatus;
  isConverted: boolean;
  dateModification?: Date;
  documentsJustificatifsOperation?: string[];
  visiteTechnique?: boolean;
  dateVisiteTechnique?: Date;
  dateEditionDevis?: Date;
  dateSignatureDevis?: Date;
  dateRAI?: Date;
  dateEmissionFacture?: Date;
  chantier?: Chantier;
  site?: Site;
  beneficiaire?: Beneficiaire;
  referentCommercial?: Personne;
  proprietaire?: Personne;
  history?: DossierHistoryItem[];
  operations?: Operation[];
  infosMPR?: InfosMPR;
  primeCEE: number;
  primeMPR: number;
  attachments: Attachment[];
  litigesFromDossierCEE?: Litige[] | null;
  litigesFromOpportunite?: Litige[] | null;
}

export interface Dossiers {
  records: DossierDetails[];
  totalSize: number;
}

export interface DossierLead {
  typeOperation?: string;
  description?: string;
  beneficiaire: Beneficiaire;
  referentCommercial: Personne;
  site: Site;
  batiment: Batiment;
  chantier: Chantier;
  modeleIncitationFinanciere?: string;
  optin?: boolean;
}

export interface CreateDossierLead {
  dossier: DossierLead;
  files: Base64File[];
}

export interface ModifyDossier {
  dateVisiteTechnique?: string;
  dateEditionDevis?: string;
  dateSignatureDevis?: string;
  dateRAI?: string;
  dateEmissionFacture?: string;
  deletedDocumentUIDs?: string[];
}

export interface InfosMPR {
  recordTypeId?: string;
  primePrecarite?: number;
  personneAcontacter?: string;
  societeNom?: string;
  entreprisePro?: Entreprise;
  signataire?: Personne;
  dateFinTravaux?: string;

  // Champs spécifiques ANAH
  dossierAnahId?: string;
  montantAideAnah?: number;
  numeroSuiviAnah?: string;
  statutAnah?: string;
  situationFinanciereMPR?: string;
}
