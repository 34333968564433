import React from 'react';
import { Typography } from 'antd';
import clsx from 'clsx';
const { Title } = Typography;

export default function Frame({
  children,
  title,
  className,
}: {
  title?: string;
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}) {
  return (
    <div
      className={clsx(
        'w-full p-4 border-2 rounded border-solid border-bleu-hellio shadow-md',
        className,
      )}
    >
      {title && (
        <Title level={3} className="!text-bleu-hellio">
          {title}
        </Title>
      )}
      {children}
    </div>
  );
}
