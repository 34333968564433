// Taille maximum autorisée pour les fichiers joints, en Mo
export const MAX_UPLOAD_FILESIZE = +(process.env.MAX_ATTACHMENT_FILESIZE || 20);

export const MAX_UPLOAD_FILESIZE_BYTES = MAX_UPLOAD_FILESIZE * 1024 * 1024;

export enum FileFormats {
  TEXT = 'text/plain',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  PDF = 'application/pdf',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

export const ALLOWED_FILE_TYPES: FileFormats[] = [
  FileFormats.TEXT, // Fichiers texte
  FileFormats.JPEG, // Images JPEG
  FileFormats.PNG, // Images PNG
  FileFormats.PDF, // Fichiers .pdf
  FileFormats.DOC, // Microsoft Word .doc
  FileFormats.DOCX, // Microsoft Word (OpenXML) .docx
];

export const ALLOWED_FILE_EXTENSIONS = [
  'pdf',
  'jpg',
  'png',
  'doc',
  'docx',
  'txt',
];
