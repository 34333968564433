export default {
  translation: {
    locale: 'fr-FR',
    'shared.french': 'Français',
    'app.name': 'Espace Partenaire Hellio',
    'auth.hero': 'Bienvenue sur votre\nEspace Partenaire',
  },
  global: {
    password: 'Mot de passe',
    email: 'E-mail',
    phone: 'Téléphone',
    logout: 'Déconnexion',
    cancel: 'Annuler',
    required: 'Ce champ est obligatoire.',
    internet_connexion_error:
      "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.",
    'email.required': 'Veuillez renseigner une adresse e-mail valide.',
    session_expired: 'Votre session a expiré. Veuillez vous reconnecter.',
    previews: 'Précédents',
    back: 'Précédent',
    next: 'Suivant',
    yes: 'Oui',
    no: 'Non',
    date: 'Date',
    mr: 'Monsieur',
    mrs: 'Madame',
    chargement: 'Chargement...',
    delete: 'Supprimer',
    invalid_email: 'Veuillez renseigner une adresse e-mail valide.',
    invalid_phone_number: 'Veuillez renseigner un numéro de téléphone valide.',
    revenu_entre: '{{min}} à {{max}} €',
    revenu_superieur: 'Supérieur à {{min}} €',
    type_acceptes: '{{type}} de fichiers acceptés : ',
    infos_generales: 'Informations générales',
  },
  filesize: {
    symbols: {
      iec: {
        bytes: {
          B: 'o',
          KiB: 'Kio',
          MiB: 'Mio',
          GiB: 'Gio',
          TiB: 'Tio',
          PiB: 'Pio',
          EiB: 'Eio',
          ZiB: 'Zio',
          YiB: 'Yio',
        },
      },
      jedec: {
        bytes: {
          B: 'o',
          KB: 'Ko',
          MB: 'Mo',
          GB: 'Go',
          TB: 'To',
          PB: 'Po',
          EB: 'Eo',
          ZB: 'Zo',
          YB: 'Yo',
        },
      },
    },
  },
  statuts_dossier: {
    EtudeEnCours: 'Étude en cours',
    OffreEmise: 'Offre émise',
    ChantierEnCours: 'Chantier en cours',
    DocumentsEnAttente: 'Documents en attente',
    Recu: 'Reçu',
    EnAttenteDeControle: 'En attente bureau de contrôle',
    Incomplet: 'Dossier non conforme',
    Valide: 'Dossier validé',
    PrimeProPayee: 'Prime Pro payée',
    PrimeBeneficiairePayee: 'Part Bénéficiaire payée',
    PrimeGlobalePayee: 'Prime Globale Payée',
    Refuse: 'Refusé',
    Abandon: 'Abandon',
    DemandeInformations: "Demande d'informations",
    EnCoursDeValidation: 'Dossier en cours de validation',
    InformationsRequises: 'Informations complémentaires requises',
    ChantierNonConforme: 'Chantier non conforme',
  },
  statuts_operation: {
    Eligible: 'Éligible',
    AConfirmer: 'Éligibilité à confirmer',
    NonConfirme: 'Éligibilité non confirmée à ce stade',
    Recommandations: 'Recommandations',
    Annule: 'Annulé',
    DossierEnCoursDeValidation: 'Dossier en cours de validation',
    Incomplet: 'Incomplet',
    RemisAuControle: 'Remis au Contrôle',
    PreValide: 'Pré validé',
    BonPourPrime: 'Bon pour Prime',
    NonConforme: 'Non conforme (Qualité)',
    ActionEnCours: 'Action en cours',
    Litige: 'Litige',
    Refuse: 'Refusé',
    Retire: 'Retiré du dépôt',
  },
  login: {
    hero: 'Connexion',
    description: 'Renseignez votre adresse email et votre mot de passe',
    forgot: 'Mot de passe oublié',
    login_error: 'Votre identifiant ou votre mot de passe est incorrect',
    submit: 'Se connecter',
    token_error: "Votre lien à expiré ou n'est plus valide",
    impersonate: 'Connecté en tant que {{name}}',
  },
  first_login: {
    hero: 'Première connexion',
    description: 'Renseignez votre mot de passe',
    success: 'Création de votre mot de passe',
    newPassword: 'Nouveau mot de passe*',
    error: "Votre lien à expiré ou n'est plus valide",
    success_description:
      'La création de votre mot de passe a bien été prise en compte.',
    success_action: 'Me connecter',
    submit: 'Valider le mot de passe',
    same: 'Les mots de passes ne sont pas identiques',
    'confirm.password': 'Confirmer mot de passe*',
    'confirm.regex.error':
      'Le mot de passe doit contenir 8 caractères : une majuscule, un chiffre et un caractère spécial.',
    'confirm.placeholder.password': 'Confirmation du mot de passe',
  },
  change_password: {
    hero: 'Changement de mot de passe',
    newPassword: 'Nouveau mot de passe*',
    description: 'Renseignez votre nouveau mot de passe',
    submit: 'Valider le mot de passe',
    success: 'Modification du mot de passe',
    success_description:
      'La modification de votre mot de passe a bien été effectuée.',
    success_action: 'Me connecter',
    same: 'Les mots de passes ne sont pas identiques',
    'confirm.password': 'Confirmer le nouveau mot de passe*',
    'confirm.regex.error':
      'Le mot de passe doit contenir 8 caractères : une majuscule, un chiffre et un caractère spécial.',
    'confirm.placeholder.password': 'Confirmation du mot de passe',
  },
  404: {
    hero: 'Page introuvable',
    description: "La page que vous cherchez n'existe pas ou a été déplacée.",
    back: "Retour à l'accueil",
  },
  not_implemented: {
    hero: 'Non implémenté',
  },
  reset_password: {
    hero: 'Mot de passe oublié',
    description:
      'Veuillez saisir votre email de connexion afin de recevoir le lien de réinitialisation de votre mot de passe.',
    submit: 'Valider',
    error: "Votre lien à expiré ou n'est plus valide",
    back: 'Retour à la connexion',
    success: 'Réinitialisation du mot de passe',
    success_title: 'Réinitialisation du mot de passe',
    success_description:
      "Nous vous avons envoyé un e-mail pour réinitialiser votre mot de passe. Pour créer votre nouveau mot de passe, il vous suffit de cliquer sur le lien contenu dans l'e-mail et d'en saisir un nouveau.<br>" +
      "<strong>Vous n'avez pas reçu cet e-mail</strong><br> Vérifiez vos courriers indésirables ou contactez : ",
    email: 'plateforme-partenaires@hellio.com',
  },
  homepage: {
    hero: 'Accueil',
  },
  contact: {
    title: 'Votre référent Hellio',
    no_contact: 'Aucun référent commercial n’est rattaché à votre compte.',
  },
  calculer_une_prime: {
    hero: 'Calculer une prime',
    description:
      'Calculez la prime Hellio associée à votre opération en quelques clics.',
    title: 'Calculateur de prime',
    champs_requis: {
      champs: "Les champs accompagnés d'un ",
      requis: ' sont requis.',
    },
    operation: 'Choisissez une opération parmi la liste',
    operation_date: "Date prévisionnelle de l'opération ",
    code_postal_label: 'Saisissez un code postal',
    entrez_code_postal: 'Entrez un code postal',
    batiment: {
      question: "De quel type de bâtiment s'agit-il ? ",
      agriccole: 'Bâtiment agricole',
      industriel: 'Bâtiment industriel',
      tertiaire: 'Bâtiment tertiaire',
      residentiel: 'Bâtiment résidentiel',
    },
    valorisation: {
      title: 'Sélectionnez la valorisation souhaitée',
      required: 'Veuillez saisir une valorisation.',
    },
    criteres_eligibilite: "Critères d'éligibilité",
    calculer_la_prime: 'Calculer la prime ',
    statut_precarite: {
      precaire: 'Précaire',
      modeste: 'Modeste',
      classique: 'Classique',
      non_renseigne: 'Non renseigné',
    },
    resultat: {
      votre_situation: 'Votre situation',
      reinitialiser_ma_simulation: 'Réinitialiser ma simulation',
      type_de_batiment: 'Type de bâtiment',
      mode_de_fonctionnement_du_site: 'Mode de fonctionnement du site',
      type_operation: "Type d'opération (FOST)",
      puissance_nominale_du_moteur: 'Puissance nominale du moteur (en Kw',
      code_postal: 'Code postal',
      montant_de_la_prime_CEE: 'Montant de la prime CEE',
      prime_MPR: "Prime MaPrimeRénov'",
      montant_de_la_prime_MPR: "Montant de la prime MaPrimeRénov'",
      montant_de_la_prime_description:
        'Le montant de prime estimé indiqué ici est calculé sur la base des paramètres que vous avez indiqué dans le calcul. Ce montant est conditionné au respect des critères d’éligibilité et est susceptible d’évoluer en fonction des travaux effectivement réalisés et de l’évolution des paramètres déclarés à ce stade',
      volume_de_CEE_reel: 'Volume de CEE réel',
      prime_globale: 'Prime globale',
      prime_pro: 'Prime professionnel',
      prime_beneficiaire: 'Prime bénéficiaire',
      prime_professionnel: 'Prime professionnel',
      repartition_beneficiaire_pro:
        'Répartition CEE bénéficiaire /' + ' professionnel',
      montant_max_prime:
        'Le montant maximum de la Prime Professionnel ne peut dépasser 50%',
      montant_prime_rappel_warning:
        'Nous vous rappelons de faire valider votre devis avec remise par' +
        ' nos équipes spécialisées avant de le faire signer à vos clients.',
      enregistrer_ma_simulation: 'Enregistrer ma simulation',
      precedent: 'Précédent',
      soumettre_un_dossier: 'Soumettre un dossier',
      enregistrer_une_simulation: 'Enregistrer une simulation',
      nom_de_la_simulation: 'Nom de la simulation',
      multiple_operations:
        "Votre devis inclut-il d'autres opérations d'économie d'énergie ? <0></0> Vous avez la possibilité d’ajouter une nouvelle opération.",
      oui_jajoute_une_operation: "Oui, j'ajoute une opération",
      non_je_soumets_mon_dossier: 'Non, je soumets mon dossier',
      veuillez_nommer_simulation: 'Veuillez nommer votre simulation',
      nom_obligatoire: 'Le nom de la simulation est obligatoire',
      nom_placeholder: 'Nom de la simulation',
      valider: 'Valider',
      confirmation_enregistrement: {
        sauvegarde_de_simulation: 'Sauvegarde de la simulation',
        simulation_bien_enregistree: 'Votre simulation a bien été enregistrée',
        simulation_probleme_enregistrement:
          'Un problème est survenu lors de l’enregistrement de votre simulation',
        acceder_historique_simulation: "Accéder à l'historique des simulations",
      },
      error: {
        montant_prime_non_calculable:
          'Nous vous invitons à soumettre votre dossier, votre chargé de' +
          ' partenariat reviendra vers vous avec une estimation de la prime.',
      },
      revenir_a_la_calculette: 'Revenir à la calculette',
    },
    travaux_mpr: {
      type_travaux_mpr: {
        title: 'Type de travaux MPR',
        placeholder: 'Type de travaux MPR',
        label: 'Saisissez un type de travaux.',
        required: 'Veuillez sélectionner un type de travaux MPR.',
      },
      nombre_personnes_logement_mpr: {
        title: 'Nombre de personnes dans le logement',
        required: 'Veuillez saisir le nombre de personnes dans le logement.',
      },
      revenu_fiscal_reference: {
        title: 'Revenu fiscal de référence',
        required: 'Veuillez sélectionner le revenu fiscal de référence.',
      },
      montant_travaux_ttc: {
        title: 'Montant des travaux TTC',
        required: 'Veuillez saisir le montant des travaux TTC.',
      },
    },
    tabs: {
      calculer_une_prime: 'Calculateur de prime',
      historique: {
        label: 'Historique de mes simulations',
        export: 'Exporter mes simulations (.csv)',
        table: {
          date: 'Date',
          name: 'Nom de la simulation',
          fost: {
            value: 'Type de bâtiment',
            label: "Libellé de l'opération",
          },
          repartitionPrime: 'Répartition CEE Bénéficiaire / Professionnel',
          volume: 'Volume CEE',
          prime: 'Montant prime CEE globale',
          montantPrimeBenef: 'Montant prime CEE Bénéficiaire',
          montantPrimePro: 'Montant prime CEE Professionnel',
        },
      },
    },
  },
  soumettre_un_dossier: {
    hero: "Soumission d'un dossier",
    etapes: {
      etape_1: 'Informations chantier',
      etape_2: 'Informations site',
      etape_3: 'Informations bénéficiaire',
      etape_4: 'Informations complémentaires',
      etape_5: 'Pièces justificatives',
    },
    champs_requis: {
      champs: "Les champs accompagnés d'un ",
      requis: ' sont requis.',
    },
    champs: {
      startDate: {
        selectionnez_date: 'Sélectionnez une date',
        value: 'Date de début des travaux',
        invalid:
          'La date de début des travaux doit être inférieure à la date de fin des travaux',
      },
      endDate: {
        value: 'Date prévisionnelle de fin des travaux',
        invalid:
          'La date de fin des travaux doit être supérieure à la date de début des travaux',
      },
      buildingType: {
        label: 'Type de site',
        placeholder: 'Sélectionnez un type de site',
        options: {
          maison: 'Maison individuelle',
          appartement: 'Appartement',
          monopropriete: 'Monopropriété',
          copropriete_immatriculee: 'Copropriété immatriculée',
          copropriete_non_immatriculee: 'Copropriété non immatriculée',
        },
      },
      siteAddress: {
        address: {
          label: 'Adresse du chantier',
          placeholder: 'Adresse du chantier',
          defaultOption: {
            value: 'Je ne trouve pas l’adresse du chantier',
          },
        },
        name: {
          label: 'Nom du site du chantier',
          placeholder: 'Nom du site du chantier',
        },
        code_postal: {
          label: 'Code postal du chantier',
          placeholder: 'Code postal du chantier',
        },
        ville: {
          label: 'Ville du chantier',
          placeholder: 'Ville du chantier',
        },
        street: {
          label: 'Adresse du chantier',
          placeholder: 'Adresse du chantier',
        },
        nbBuildings: {
          label: 'Nombre de bâtiments',
          placeholder: 'Nombre de bâtiments',
        },
        nbUnits: {
          label: "Nombre de lots d'habitation",
          placeholder: "Nombre de lots d'habitation",
        },
        heatingType: {
          label: 'Type de chauffage',
          placeholder: 'Sélectionnez un type de chauffage',
          options: {
            combustible: 'Combustible',
            fioul: 'Fioul',
            electric: 'Electricité',
            gas: 'Gaz',
          },
        },
        registrationNumber: {
          label: 'N° d’immatriculation au registre des copropriétés',
          placeholder: 'N° d’immatriculation au registre des copropriétés',
        },
        distribution: {
          label: 'Mode de répartition du chauffage',
          options: {
            collective: 'Collectif',
            individual: 'Individuel',
          },
        },
        constructionYear: {
          label: 'Année de construction',
          placeholder: 'Sélectionnez une année de construction',
          options: {
            before1948: 'Avant 1948',
            before1960: 'Avant 1960',
            bw1960_1980: 'Entre 1960 et 1980',
            bw1980_2005: 'Entre 1980 et 2005',
            after2005: 'Après 2005',
          },
        },
        postalCode: {
          label: 'Code postal du chantier',
          placeholder: 'Code postal du chantier',
        },
        city: {
          label: 'Ville du chantier',
          placeholder: 'Ville du chantier',
        },
      },
      beneficiary: {
        label: 'Type de bénéficiaire',
        options: {
          company: 'Personne morale',
          individual: 'Personne physique',
        },
        intitule: 'Informations du bénéficiaire',
      },
      siret_beneficiaire: {
        label: 'SIRET ou dénomination sociale du bénéficiaire',
        placeholder: 'SIRET ou dénomination sociale du bénéficiaire',
        defaultOption: {
          value: 'Je ne trouve pas le numéro siret du bénéficiaire',
        },
      },
      manuelSiret: {
        label: 'Saisissez le SIRET du bénéficiaire',
        placeholder: 'SIRET',
      },
      siret_entreprise_travaux: {
        label: 'SIRET ou dénomination sociale de l’entreprise de travaux',
        placeholder: 'SIRET ou dénomination sociale de l’entreprise de travaux',
        defaultOption: {
          value: "Je ne trouve pas le SIRET de l'entreprise de travaux",
        },
      },
      contactIdParticulier: {
        label: 'Prénom et nom du contact bénéficiaire',
        placeholder: 'Prénom et nom du contact bénéficiaire',
        defaultOption: {
          value: 'Je ne trouve pas le prénom et nom du contact bénéficiaire',
        },
      },
      contactIdEntreprise: {
        label: 'Prénom et nom du référent bénéficiaire',
        placeholder: 'Prénom et nom du référent bénéficiaire',
        defaultOption: {
          value: 'Je ne trouve pas le prénom et nom du référent bénéficiaire',
        },
      },
      precariousness: {
        label: 'Statut de la précarité',
        options: {
          non_precarious: 'Non précaire',
          precarious: 'Précaire',
          very_precarious: 'Grand précaire',
        },
      },
      beneficiaireParticulier: {
        title: {
          label: 'Civilité du contact bénéficiaire',
        },
        lastName: {
          label: 'Nom du contact bénéficiaire',
          placeholder: 'Nom du contact bénéficiaire',
        },
        firstName: {
          label: 'Prénom du contact bénéficiaire',
          placeholder: 'Prénom du contact bénéficiaire',
        },
        email: {
          label: 'Adresse e-mail du contact bénéficiaire',
          placeholder: 'Email du contact bénéficiaire',
        },
        phone: {
          label: 'Téléphone du contact bénéficiaire',
          placeholder: 'Numéro du contact bénéficiaire',
        },
        isAddressSameAsSite: {
          label:
            "Est-ce que l'adresse du contact bénéficiaire est la même que l'adresse du chantier ?",
        },
      },
      beneficiaireEntreprise: {
        title: {
          label: 'Civilité du référent bénéficiaire',
        },
        lastName: {
          label: 'Nom du référent bénéficiaire',
          placeholder: 'Nom du référent bénéficiaire',
        },
        firstName: {
          label: 'Prénom du référent bénéficiaire',
          placeholder: 'Prénom du référent bénéficiaire',
        },
        email: {
          label: 'Adresse e-mail du référent bénéficiaire',
          placeholder: 'Email du référent bénéficiaire',
        },
        phone: {
          label: 'Téléphone du référent bénéficiaire',
          placeholder: 'Numéro du référent bénéficiaire',
        },
        isAddressSameAsSite: {
          label:
            "Est-ce que l'adresse du référent bénéficiaire est la même que l'adresse du chantier ?",
        },
      },

      address: {
        label: 'Adresse du bénéficiaire',
        placeholder: 'Adresse du bénéficiaire',
      },
      city: {
        label: 'Ville du bénéficiaire',
        placeholder: 'Ville du bénéficiaire',
      },
      postalCode: {
        label: 'Code postal du bénéficiaire',
        placeholder: 'Code postal du bénéficiaire',
      },

      isElectronicContract: {
        label:
          'Autoriser Hellio à envoyer des documents en signature électronique',
      },
      comment: {
        label: 'Commentaires et spécifications du projet :',
        placeholder: 'Commentaires',
      },
      SiretInfos: {
        siret_entreprise_travaux: {
          label: "Saisissez le SIRET de l'entreprise de travaux",
          placeholder: "SIRET de l'entreprise de travaux",
          noData: "Je ne trouve pas l'adresse",
        },
      },
      interlocuteur: {
        label: "Interlocuteur de l'entreprise de travaux ",
        placeholder: "Interlocuteur de l'entreprise de travaux ",
        defaultOption: {
          value: "Je ne trouve pas l'interlocuteur de l'entreprise de travaux",
        },
      },
      interlocuteur_infos: {
        civilite: {
          label: 'Civilité',
          mrs: 'Madame',
          mr: 'Monsieur',
        },
        lastName: {
          label: 'Nom de l’interlocuteur',
          placeholder: 'Nom de l’interlocuteur',
        },
        firstName: {
          label: 'Prénom de l’interlocuteur',
          placeholder: 'Prénom de l’interlocuteur',
        },
        email: {
          label: 'Adresse e-mail de l’interlocuteur',
          placeholder: 'Adresse e-mail de l’interlocuteur',
        },
        phone: {
          label: 'Téléphone de l’interlocuteur',
          placeholder: 'Téléphone de l’interlocuteur',
        },
        entreprise_name: {
          label: "Nom de l'entreprise de l'interlocuteur",
          placeholder: "Nom de l'entreprise de l'interlocuteur",
        },
      },
    },
    soumettre_mon_dossier: 'Soumettre mon dossier',

    modale: {
      dossier_soumis: 'Dossier soumis',
      acceder_suivi_dossiers: 'Accéder au suivi de dossiers',
      description_part1: 'Votre dossier a été enregistré avec succès.',
      description_part2:
        'Rendez-vous dans l’onglet "Suivi de dossiers" pour le consulter et continuer son instruction.',
      error: "Une erreur s'est produite lors de la soumission de votre dossier",
    },
    fields: {
      erreurs: {
        siret_14_caracteres: 'Le SIRET doit contenir 14 caractères',
        siret_chiffres: 'Le SIRET doit être composé uniquement de chiffres',
        nombre_positif: 'Le nombre de bâtiments doit être positif',
        invalid_number: 'Le champs ne doit contenir que des chiffres',
        invalid_postal_code: 'Le code postal doit être composé de 5 chiffres',
      },
      siret: 'Numéro SIRET',
      siret_non_trouve: "Je ne trouve pas le SIRET de l'entreprise de travaux",
      interlocuteur: 'Interlocuteur',
      interlocuteur_non_trouve:
        "Je ne trouve pas l'interlocuteur de l'entreprise de travaux",
    },
  },
  dossiers: {
    hero: 'Suivi de dossier',
    filter: 'Filtres',
    referent: 'Référent commercial',
    keyword: 'Référence chantier, adresse des travaux...',
    filter_statuts: 'Filtrer par statuts',
    filter_referent: 'Filtrer par référent commercial',
    export: 'Exporter les données (.csv)',
    reset: 'Réinitialisation',
  },
  dossier_table: {
    no_data: 'Aucune donnée disponible',
    beneficiaire: 'Bénéficiaire',
    site_nom: 'Nom du site chantier',
    site_adresse: 'Adresse des travaux',
    dateModification: 'Date de mise à jour',
    chantier_dateFinTravaux: 'Date de fin des travaux',
    reference: 'Référence chantier',
    montant_primes: 'Montant des primes',
    statut: 'Statut',
    referentCommercial: 'Référent commercial',
    operation_libelle: 'Opération',
    operation_fost: 'Fiche d’opération standardisée',
    operation_montantPrime: 'Montant prime d’opération CEE',
    operation_montant_mpr: 'Montant MaPrimeRénov’',
    details: 'Voir détail',
    user: "Raison sociale de l'installateur",
    default_type_logement: 'Logement',
    nom_site_particulier: '{{type}} de {{civilite}} {{prenom}} {{nom}}',
    aucune_operation: "Il n'existe aucune opération pour ce dossier.",
  },
  dossier: {
    hero: 'Vue détaillée du dossier',
    history_title: 'Historique des statuts',
    reference_chantier: 'Référence chantier',
    reference_dossier: 'Référence du dossier',
    statut_dossier: 'Statut du dossier',
    referent_commercial: 'Référent commercial',
    coordonnees_beneficiaire: 'Coordonnées du Bénéficiaire',
    raison_sociale: 'Raison sociale',
    siren: 'Numéro de SIREN',
    adresse_siege: 'Adresse du siège',
    nom_beneficiaire: 'Nom du bénéficiaire',
    prenom_beneficiaire: 'Prénom du bénéficiaire',
    email_beneficiaire: 'Email du bénéficiaire',
    telephone_beneficiaire: 'Téléphone du bénéficiaire',
    chantier: 'Chantier',
    nom_site: 'Nom du site',
    adresse_chantier: 'Adresse du chantier',
    raison_sociale_installateur: 'Raison sociale de l’installateur',
    siret_installateur: 'SIRET de l’installateur',
    date_fin_travaux: 'Date de fin de travaux',
    montant_chantier: 'Montant de la prime CEE',
    modele_incitation: 'Choix du modèle d’incitation financière',
    operations: 'Opérations',
    non_conformite: 'Non-conformité',
    importer_mon_document_corrige: 'Importer mon document corrigé',
    messagerie: 'Messagerie',
    info_operations: 'Informations concernant les opérations',
    libelle_operation: "Libellé de l'opération",
    fiche_operation_standardisee: "Fiche d'opération standardisée",
    fost: 'FOST',
    criteres_eligibilite: "Critères d'éligibilité",
    mentions_facture: 'Mentions à faire figurer sur le devis / facture',
    montant_prime_operation: "Montant prime d'opération CEE",
    pieces_dossier: 'Pièces du dossier',
    statut: 'Statut',
    aucun: 'Aucun',
    montant_de_la_prime_MPR: 'Montant de la prime MaPrimeRénov’',
    maprimerenov: 'MaPrimeRénov’',
    n_de_dossier: 'N° de dossier',
    documents: 'Documents',
    documents_hellio: 'Documents Hellio',
    pieces_justificatives: 'Pièces justificatives',
    no_file: 'Aucun fichier',
    tout_supprimer: 'Tout supprimer',
  },
  litige_dossier: {
    etat_action: {
      traitement_en_cours: 'Traitement en cours',
      terminee: 'Terminée',
    },
    file_modified: 'modifié le {{date}} – {{size}} – {{type}}',
    column_headers: {
      documents: 'Documents',
      non_conformites_detectees: 'Non-conformités détectées',
      actions_correctives_preconisees: 'Actions correctives préconisées',
      commentaire_hellio: 'Commentaire Hellio',
      actions_a_realiser: 'Actions à réaliser',
    },
    erreurs: {
      aucun_selectionne: 'Aucun litige sélectionné.',
      upload:
        "Erreur lors de l'envoi des fichiers pour le litige {{litigeId}}.",
    },
  },
  documents_dossier: {
    debut_travaux: 'Pièces justificatives de début des travaux',
    debut_travaux_hint:
      '(Devis, Ordre de service, Bon de commande, Acte d’engagement)',
    date_visite_technique: 'Date de la visite technique',
    date_edition: "Date d'édition du document",
    date_edition_hint:
      "La date d'édition doit être la même que sur celle du document.",
    signe: 'Le document est-il signé ?',
    date_signature: 'Date de signature du document',

    preuve_role: 'Preuves du rôle actif et incitatif',
    preuve_role_hint:
      '(Accord d’incitation financière AIF, Cadre contribution)',

    fin_travaux: 'Pièces justificatives de fin des travaux',
    fin_travaux_hint: '(Facture, PV de réception)',

    attestation_honneur: "Attestations sur l'honneur",

    documents_techniques: 'Certifications et docs techniques',
    documents_techniques_hint:
      '(Certifications RGE, Fiches techniques des équipements)',

    documents_operation: "Pièces spécifiques à l'opération",

    documents_autres: 'Autres',
    documents_autres_hint:
      "(Avis d’imposition, pour MaPrimeRénov’ : Mandat, Justificatif de propriété, Attestation d'indivision, Attestation bailleur)",

    upload_hint: 'Glissez vos documents ou {{action}}.',
    upload_hint_action: 'cliquez ici pour les charger',
    erreurs: {
      date_edition_devis:
        "La date d'édition du devis doit être antérieure à la date de signature.",
      date_signature_devis:
        "La date de signature doit être ultérieure à la date d'édition.",
      date_signature_rai_devis:
        'La date de signature de la preuve du rôle actif et incitatif doit être antérieure à celle de la pièce justificative de début des travaux.',
      save: "Erreur lors de l'enregistrement des données.",
    },

    upload_files: 'Déposer des fichiers',
    cancel_upload: 'Annuler',
    validate_upload: 'Valider',

    formats_acceptes: 'Formats de fichier acceptés : {{types}}',
    taille_acceptee:
      'Taille de fichier acceptée : maximum {{taille}} par fichier',
  },
  attachments: {
    ajouter_fichier: 'Ajouter un fichier',
    deposez_fichiers: 'ou déposez vos fichiers ici',
    error_required: 'Vous devez joindre au moins un fichier.',
    error_type: "Le type de fichier {{type}} n'est pas autorisé.",
    error_allowed_types: 'Seuls les fichiers {{types}} sont autorisés.',
    error_size: 'Vous ne pouvez pas joindre de fichier de plus de {{size}}.',
    error_max_files: 'Vous ne pouvez pas joindre plus de {{max}} fichiers.',
    download_error: 'Erreur de téléchargement',
    download_error_description:
      "Le fichier {{file}} n'a pas pu être téléchargé :",
    delete_error: 'Erreur de suppression',
    delete_error_description: "Le fichier {{file}} n'a pas pu être supprimé :",
    upload_error: 'Erreur de chargement',
    upload_error_description: "Le fichier {{file}} n'a pas pu être ajouté :",
  },
  attachments_info_banner: {
    type_acceptes: 'Formats de fichiers acceptés : {{types}}',
    taille_max: 'Taille de fichiers acceptée : {{size}}',
    nombre_fichiers: 'Nombre de fichiers accepté : {{max}} fichiers',
    nombre_fichiers_single: 'Nombre de fichiers accepté : {{max}} fichier',
  },
  faq: {
    title: 'FAQ',
    hero_0: 'Foire aux',
    hero_1: 'questions',
  },
  mon_compte: {
    hero: 'Mon compte',
    lastname: 'Nom',
    firstname: 'Prénom',
    email: 'E-mail',
    deconnexion: 'Se déconnecter',
  },
  resend_link_first_login: {
    title: 'Votre lien de connexion a expiré',
    action: 'Recevoir un nouveau lien',
    description:
      'Votre lien de connexion a expiré. Pour des raisons de sécurité, les liens de connexion sont valables uniquement pendant une durée limitée.',
    success_title: 'Votre lien de connexion a été envoyé par mail',
    success_description:
      "<strong>Vous n'avez pas reçu cet e-mail</strong><br> Vérifiez vos courriers indésirables ou contactez :<br>",
  },
  errors: {
    correspondance: 'Aucune correspondance trouvée pour {{objet}}.',
  },
};
