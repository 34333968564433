import React, {
  useState,
  useCallback,
  useMemo,
  ReactNode,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { App } from 'antd';
import BaseService from 'services/api/BaseService';
import { AttachmentsUrls } from 'services/api/urls';
import type { UploadFile, UploadProps } from 'antd';
import defaultRequest from 'rc-upload/es/request';
import { UploadRequestFile, UploadRequestOption } from 'rc-upload/es/interface';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  ALLOWED_FILE_TYPES,
  FileFormats,
  MAX_UPLOAD_FILESIZE_BYTES,
  ALLOWED_FILE_EXTENSIONS,
} from 'const/attachments';
import useFilesize from 'hooks/useFilesize';
import { Rule } from 'antd/lib/form';

interface OnChangeOpts {
  file: UploadFile;
  fileList: UploadFile[];
}

export const useUploadAttachmentProps = (
  attachments: UploadFile[],
  action: string,
  onFileListChanged: () => void = () => {},
): UploadProps => {
  const { t } = useTranslation();
  const currentAction = useRef(action);
  const [fileList, setFileListInternal] = useState<UploadFile[]>(attachments);
  const { notification } = App.useApp();
  const filesize = useFilesize();

  const deleteAttachment = useDeleteAttachment();
  const downloadAttachment = useDownloadAttachment();

  useEffect(() => {
    currentAction.current = action;
    return () => {
      currentAction.current = '';
    };
  }, [action]);

  const setFileList = useCallback(
    (newFileList: UploadFile[]) => {
      if (currentAction.current === action) {
        setFileListInternal(newFileList);
      }
    },
    [action],
  );

  useEffect(() => {
    setFileList(attachments);
  }, [attachments, setFileList]);

  const displayError = useCallback(
    (file: UploadFile, message: ReactNode) => {
      notification.open({
        icon: <ExclamationCircleOutlined />,
        message: t('attachments:upload_error'),
        description: (
          <>
            {t('attachments:upload_error_description', {
              file: file.name,
            })}
            <br />
            {message}
          </>
        ),
      });
    },
    [notification, t],
  );

  const checkAndDownloadAttachment = useCallback(
    (file: UploadFile) => {
      if (file.url && file?.linkProps?.deleting !== 'true') {
        downloadAttachment(file);
      }
    },
    [downloadAttachment],
  );

  const replaceFile = useCallback(
    (file: UploadFile) => {
      const newFileList = fileList.map((existingFile) =>
        file.uid === existingFile.uid ? file : existingFile,
      );
      setFileList(newFileList);
    },
    [fileList, setFileList],
  );

  const onChange = useCallback(
    ({ file, fileList: newFileList }: OnChangeOpts) => {
      // Réordonne la liste des fichiers pour mettre les plus récents en premier
      setFileList(
        newFileList.sort((fileA: UploadFile, fileB: UploadFile) =>
          `${fileB.lastModifiedDate}`.localeCompare(
            `${fileA.lastModifiedDate}`,
          ),
        ),
      );
      if (file.status === 'error') {
        displayError(file, file.response.message);
      } else if (file.status === 'done') {
        file.uid = file.response.id;
        file.url = '#'; // Définit un URL vide, le téléchargement étant géré de façon asynchrone
      }
    },
    [displayError, setFileList],
  );

  const onRemove = useCallback(
    async (file: UploadFile): Promise<boolean> => {
      // Fichier déjà en cours de suppression
      if (file?.linkProps?.deleting === 'true') {
        return false;
      }
      // On ne peut supprimer que les fichiers téléchargeables (qui ont un URL)
      if (file.url) {
        const linkProps = file?.linkProps || {};
        replaceFile({
          ...file,
          linkProps: { ...linkProps, deleting: 'true' },
          url: undefined,
        });
        const result = await deleteAttachment(file);
        onFileListChanged();
        replaceFile({
          ...file,
          linkProps: { ...linkProps, deleting: 'false' },
          url: result ? undefined : '#',
        });
        return result;
      }
      return false;
    },
    [replaceFile, deleteAttachment, onFileListChanged],
  );

  const beforeUpload = useCallback(
    (file: UploadFile) => {
      const errors = [];
      // Vérification du type
      if (!ALLOWED_FILE_TYPES.includes(file.type as FileFormats)) {
        errors.push(
          t('attachments:error_type', {
            type: file.type,
          }),
        );
      }

      // Vérification de la taille
      if (+(file.size || 0) > MAX_UPLOAD_FILESIZE_BYTES) {
        errors.push(
          t('attachments:error_size', {
            size: filesize(MAX_UPLOAD_FILESIZE_BYTES),
          }),
        );
      }

      // Affichage et retour d'erreur
      if (errors.length) {
        displayError(
          file,
          <ul>
            {errors.map((error) => (
              <li key={error}>{error}</li>
            ))}
          </ul>,
        );
        file.status = 'error';
        return false;
      }

      return true;
    },
    [displayError, filesize, t],
  );

  const customRequest = useCallback(
    ({ onSuccess, ...args }: UploadRequestOption) => {
      const hookedOnSuccess = (
        body: object,
        fileOrXhr?: UploadRequestFile | XMLHttpRequest,
      ) => {
        onFileListChanged();
        if (onSuccess) {
          onSuccess(body, fileOrXhr);
        }
      };
      return defaultRequest({ onSuccess: hookedOnSuccess, ...args });
    },
    [onFileListChanged],
  );

  return useMemo(
    () => ({
      action,
      headers: Object.fromEntries(
        Array.from(BaseService.getHeadersAuth(true).entries()),
      ),
      customRequest,
      onRemove,
      onChange,
      onDownload: checkAndDownloadAttachment,
      onPreview: checkAndDownloadAttachment,
      beforeUpload,
      fileList,
    }),
    [
      action,
      beforeUpload,
      checkAndDownloadAttachment,
      customRequest,
      fileList,
      onChange,
      onRemove,
    ],
  );
};

export const useDownloadAttachment = (): ((
  attachment: UploadFile,
) => Promise<void>) => {
  const { t } = useTranslation();
  const { notification } = App.useApp();

  const displayError = (message: string, fileName: string) =>
    notification.open({
      icon: <ExclamationCircleOutlined />,
      message: t('attachments:download_error'),
      description: (
        <>
          {t('attachments:download_error_description', {
            file: fileName,
          })}
          <br />
          {message}
        </>
      ),
    });

  return async (attachment: UploadFile): Promise<void> => {
    try {
      const response = await BaseService.getRequest(
        AttachmentsUrls.GET_DOWNLOAD(attachment.uid),
        true,
      );
      const data = await response.json();
      if ([200, 201].includes(response.status)) {
        const link = document.createElement('a');
        link.href = AttachmentsUrls.DOWNLOAD_ATTACHMENT(data.token);
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
      } else {
        displayError(data?.message, attachment.name);
      }
    } catch (err: unknown) {
      const e = err as Error;
      console.warn('Delete attachment error', e);
      displayError(
        e.message || t('global:internet_connexion_error'),
        attachment.name,
      );
    }
  };
};

export const useDeleteAttachment = (): ((
  attachment: UploadFile,
) => Promise<boolean>) => {
  const { t } = useTranslation();
  const { notification } = App.useApp();

  const displayError = (message: string, fileName: string) =>
    notification.open({
      icon: <ExclamationCircleOutlined />,
      message: t('attachments:delete_error'),
      description: (
        <>
          {t('attachments:delete_error_description', {
            file: fileName,
          })}
          <br />
          {message}
        </>
      ),
    });

  return async (attachment: UploadFile): Promise<boolean> => {
    try {
      const response = await BaseService.deleteRequest(
        AttachmentsUrls.DELETE(attachment.uid),
        true,
      );
      if ([200, 201].includes(response.status)) {
        return true;
      }
      const data = await response.json();
      displayError(data?.message, attachment.name);
    } catch (err: unknown) {
      const e = err as Error;
      console.warn('Delete attachment error', e);
      displayError(
        e.message || t('global:internet_connexion_error'),
        attachment.name,
      );
    }
    return false;
  };
};

export function useFileFormItemRules({
  required = false,
  maxFiles = 10,
  maxSize = MAX_UPLOAD_FILESIZE_BYTES,
  fileTypes = ALLOWED_FILE_TYPES,
  fileTypesDisplay = ALLOWED_FILE_EXTENSIONS,
}: {
  required?: boolean;
  maxFiles?: number | null;
  maxSize?: number | null;
  fileTypes?: string[] | null;
  fileTypesDisplay?: string[] | null;
} = {}): Rule[] {
  const { t } = useTranslation();
  const filesize = useFilesize();
  return useMemo(() => {
    const rules: Rule[] = [];
    if (required) {
      rules.push({
        validator: (_: unknown, value: UploadFile[]) =>
          value?.length
            ? Promise.resolve()
            : Promise.reject(new Error(t('attachments:error_required'))),
      });
    }
    if (maxFiles) {
      rules.push({
        validator: (_: Rule, value: UploadFile[]) =>
          value?.length && value.length > maxFiles
            ? Promise.reject(
                new Error(
                  t('attachments:error_max_files', {
                    max: maxFiles,
                  }),
                ),
              )
            : Promise.resolve(),
      });
    }
    if (maxSize) {
      rules.push({
        validator: (_: Rule, value: UploadFile[]) =>
          value
            ?.filter(({ url }) => !url) // Ignorer les fichiers existants
            ?.find((file: UploadFile) => (file.size || 0) > maxSize)
            ? Promise.reject(
                new Error(
                  t('attachments:error_size', {
                    size: filesize(maxSize),
                  }),
                ),
              )
            : Promise.resolve(),
      });
    }
    if (fileTypes?.length) {
      rules.push({
        validator: (_: Rule, value: UploadFile[]) =>
          value
            ?.filter(({ url }) => !url) // Ignorer les fichiers existants
            ?.find(({ type }) => !fileTypes.includes(type as FileFormats))
            ? Promise.reject(
                new Error(
                  t('attachments:error_allowed_types', {
                    types: (fileTypesDisplay || fileTypes).join(', '),
                  }),
                ),
              )
            : Promise.resolve(),
      });
    }
    return rules;
  }, [fileTypes, fileTypesDisplay, filesize, maxFiles, maxSize, required, t]);
}
