export function getEnv(): 'prod' | 'staging' | 'dev' | 'local' {
  const hostname = window.location.hostname;
  const port = window.location.port;

  switch (hostname) {
    case process.env.REACT_APP_PROD_HOST:
      return 'prod';
    case process.env.REACT_APP_STAGING_HOST:
      return 'staging';
    case process.env.REACT_APP_DEV_HOST:
      return 'dev';
    default:
      return port === '3000' ? 'local' : 'dev';
  }
}
