import React, { useMemo, useCallback } from 'react';
import { Typography, Button, Upload, Flex, Spin } from 'antd';
import type { UploadFile, UploadProps } from 'antd';
import useFilesize from 'hooks/useFilesize';
import useFiletype from 'hooks/useFiletype';
import { useDateFormat } from 'hooks/useDateFormat';
import { useUploadAttachmentProps } from 'hooks/attachments.hooks';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg';
import { UploadOutlined } from '@ant-design/icons';
import clsx from 'clsx';

const { Dragger } = Upload;

export function useMultiUploadList(maxSize?: number) {
  const filesize = useFilesize();
  const filetype = useFiletype();
  const format = useDateFormat();

  const extra = useCallback(
    ({ size = 0, lastModifiedDate = new Date(), name }: UploadFile) => (
      <>
        <Typography.Text className="float-right">
          {format(lastModifiedDate, 'PP')} &ndash;{' '}
          <span
            className={clsx({
              '!text-red-500': maxSize && size > maxSize,
            })}
          >
            {filesize(size)}
          </span>{' '}
          &ndash; {filetype(name)}
        </Typography.Text>
      </>
    ),
    [filesize, filetype, format, maxSize],
  );

  const removeIcon = useCallback(
    ({ linkProps }: UploadFile) =>
      linkProps?.deleting !== 'true' ? (
        <CrossIcon className="inline-block !align-middle !text-bleu-hellio mr-2 mb-1 cursor-pointer" />
      ) : (
        <Spin
          size="small"
          className="inline-block !align-middle !text-bleu-hellio mr-2 mb-1 cursor-wait"
        />
      ),
    [],
  );

  return useMemo(
    () => ({
      extra,
      showDownloadIcon: false,
      showRemoveIcon: true,
      removeIcon,
    }),
    [extra, removeIcon],
  );
}

export function MultiUpload({
  attachments,
  action,
  onFileListChanged,
}: {
  attachments: UploadFile[];
  action: string;
  onFileListChanged?: () => void;
}) {
  const { t } = useTranslation();
  const uploadProps: UploadProps = useUploadAttachmentProps(
    attachments,
    action,
    onFileListChanged,
  );
  const showUploadList = useMultiUploadList();
  return (
    <Dragger {...uploadProps} showUploadList={showUploadList} multiple>
      <Flex gap="large">
        <Button type="primary" icon={<UploadOutlined />}>
          {t('attachments:ajouter_fichier')}
        </Button>
        <p className="ant-upload-text grow !-mb-1 pt-1">
          {t('attachments:deposez_fichiers')}
        </p>
      </Flex>
    </Dragger>
  );
}

export default MultiUpload;
