import { initReactI18next } from 'react-i18next';
import i18n, { use } from 'i18next';
import translations from 'translations/locales';

use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: translations,
    lng: 'fr',
    supportedLngs: ['fr'],
    fallbackLng: 'fr',
    keySeparator: '.',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
